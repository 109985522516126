<template>
  <main>
    <header
      class="bg-green-alt py-30 md:py-40 lg:py-60 relative logo-background"
    >
      <Container>
        <h1
          class="text-white text-center font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl mb-30 md:mb-40 lg:mb-60"
        >
          {{ $texts('pharmagoHomeTitle', 'Finden Sie Ihre Apotheke') }}
        </h1>
        <NavigatorSearchBar key="searchbar-home" />

        <ul
          class="mt-30 md:mt-40 lg:mt-50 flex gap-10 items-center justify-center flex-wrap max-w-prose mx-auto"
        >
          <li v-for="term in popularServices">
            <NuxtLink
              v-if="isPositionService(term.portfolioType?.name || '')"
              :to="{
                name: 'navigator',
                query: { facets: 'positions:' + term.id },
              }"
              class="button is-small-mobile"
            >
              {{ term.name }}
            </NuxtLink>
            <NuxtLink
              v-else
              :to="{
                name: 'navigator',
                query: { facets: 'services:' + term.id },
              }"
              class="button is-small-mobile"
              >{{ term.name }}</NuxtLink
            >
          </li>
        </ul>
      </Container>
    </header>

    <BlokkliProvider v-bind="blokkli">
      <Container class="mt-30 md:mt-50 lg:mt-60">
        <BlokkliField :list="node?.paragraphs" name="field_paragraphs" />
      </Container>

      <Container>
        <BlokkliField
          :list="node?.paragraphsFooter"
          name="field_paragraphs_footer"
          field-list-type="footer"
        />
      </Container>
    </BlokkliProvider>
  </main>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'
import {
  HomeBoxFragment,
  NavigatorPopularServiceFragment,
  NodePageFragment,
} from '#graphql-operations'
import { isPositionService } from '~/helpers/isPositionService'

definePageMeta({
  name: 'home',
  drupalRoute: true,
})

setBreadcrumbLinks()
const { $texts } = useNuxtApp()

provide('isFrontPage', true)

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, () =>
  useCachedGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => v.data),
)

// Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query.value)

const { data: homeConfig } = await useLazyAsyncData(() =>
  useCachedGraphqlQuery('home').then((v) => v.data.home),
)

const { data: popularServices } = await useLazyAsyncData<
  NavigatorPopularServiceFragment[]
>(() =>
  useCachedGraphqlQuery('navigatorConfig').then(
    (v) => v.data.config?.popularServices || [],
  ),
)

const boxes = computed(() => {
  return (homeConfig.value?.homeParagraphs?.filter(
    (v) => v.__typename === 'ParagraphMultiButtonBox',
  ) || []) as HomeBoxFragment[]
})

provide('home_teasers', boxes)

useRouteCache((c) => c.setCacheable().setMaxAge(600))
await renderPageDependencies()
const blokkli = buildBlokkliProps(node, 'node', 'page')
</script>
